import React, { useState,useRef } from 'react';
import emailjs from '@emailjs/browser';
import './RequestForm.css';

function RequestForm() {
  const form = useRef();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    position: '',
    department: '',
    company: '',
    country: '',
    requirements: '',
  });
  const handleSubmit = (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm('service_7g65xgv', 'template_3rmmu13', form.current, {
        publicKey: 'hWMJVC_Y_wsqaO1NT',
      })
      .then(
        () => {
          alert('Email Sent!');
          // Reset form data
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            contactNumber: '',
            position: '',
            department: '',
            company: '',
            country: '',
            requirements: '',
          });
          // Optionally reset the form itself
          form.current.reset();
        },
        (error) => {
          alert('FAILED...', error.text);
        },
      );
  };
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Submit form data to the server or process it here
  //   alert('Form submitted!');
  // };

  return (
    <section id="request-form" className="request-form">
      <h2>Submit a Request</h2>
      <form ref={form} onSubmit={handleSubmit}>
        <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
        <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
        <input type="text" name="contactNumber" placeholder="Contact Number" value={formData.contactNumber} onChange={handleChange} />
        <input type="text" name="position" placeholder="Position" value={formData.position} onChange={handleChange} />
        <input type="text" name="department" placeholder="Department" value={formData.department} onChange={handleChange} />
        <input type="text" name="company" placeholder="Company Name" value={formData.company} onChange={handleChange} />
        <input type="text" name="country" placeholder="Country" value={formData.country} onChange={handleChange} />
        <textarea name="requirements" placeholder="Project Requirements" value={formData.requirements} onChange={handleChange}></textarea>
        <input type="submit" value="Send" />
      </form>
    </section>
  );
}

export default RequestForm;
