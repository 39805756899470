import React from 'react'
import aboutImage from '../images/qlife.jpeg';

const Qlife = () => {
  return (
    <>
      <div className='about-section m-5 p-5'>
        <div className="container">
        <h1 className='text-center'>OUR PARTNERS</h1>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="about-image">
                <img className='img-fluid about-image' src={aboutImage} alt='' />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div class="align-self-center pt-2">
                <div className='about-text'>
                <h2 className='py-2'>We are now offically partnered with QLIFE for better life</h2>


                  <h2 className='py-4'>Qlife Pharma Partner company which is based in Dubai</h2>

                    <p>Qlife Pharma Helps pharma companies to distribute and supply the medicinal products across gulf region
                    </p>           
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default Qlife