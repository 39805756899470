import React from 'react';
import './ContactUs.css';
import RequestForm from './RequestForm';

function ContactUs() {
  return (
    <>
      <div className="container section-vertical-padding">
        <div className="row">
          <div className="col-md-6">
            <RequestForm />
          </div>
          <div className="col-md-6">
            <>
              <section id="contact" className="contact-us">
                <h2>Contact Us</h2>
                <p>4th Floor, 1 Centenary Way, Manchester, M50 1RF, United Kingdom</p>
                <p>Phone: +44 1615042500 | Mobile: +447587570977</p>
                <p>Email: <a href="mailto:admin@fapharma.co.uk">admin@fapharma.co.uk</a></p>
              </section>
            </>

            <>
              <div className="map-section pt-4">
                <h4 className="map-title">Our Location</h4>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.254091039166!2d-2.3289491243291196!3d53.48178966458188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487baef17c16eb17%3A0xca2399b008fe1298!2s1%20Centenary%20Way%2C%20Stretford%2C%20Salford%20M50%201RF%2C%20UK!5e0!3m2!1sen!2sin!4v1737394103501!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Map"
                ></iframe>
              </div>
            </>

          </div>
        </div>
      </div>



    </>
  );
}

export default ContactUs;
